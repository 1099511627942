.Values {
  width: 100%;
  background-color: white;
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
}
.valueItem {
  width: 400px;
  border-radius: 10px;
  box-shadow: #282c34;
  /* background-color: #282c34; */
  padding: 0;
  color: black;
  margin: 1rem;
}
.ValueH1 {
}
.ValueP {
  text-indent: 20%;
  text-align: justify;
  font-size: 80%;
}
