
  .card-container{
    margin: 3rem 0 0rem 0;
    flex-wrap: wrap;
    display: flex;
    text-align: center;
    gaP:10px;
    justify-content: space-evenly;
  }
  .intro-card{
    min-height: 200px;
    height: 100%;
    padding: 1rem 0 1rem 0;
    max-width: 550px;
    min-width: 270px;
    background-color: aquamarine;
    /* margin: 30px; */
    border-radius: 1rem;
    background-color: #d1b7fc;
    color:black;
    margin-bottom: 2em;
  }
  .intro-details{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    text-align: left;
    gaP:35px;
    align-items: start;
    /* font-size: 80%; */
    justify-content: space-between;
  width: 100%;
  }
  .card-h1{
    margin-bottom: 1rem;
  }
  .intro-p{
    display: flex;
    justify-content: start;
    align-items: start;
    text-align: center;
    /* width: 20%; */

  }
.text-fontSize{
  font-size: 11px;
}
.intro-li{
  list-style-type: disc !important;
}
  .intro-second-details{
    padding: 0 1rem 0 1rem;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: start;
    gap:10px;
  
  }
  .intro-sec-p{
    flex:1; 
    /* font-size: 80%; */
  }
  
  .intro-card h2{
    color:#8F57FF
  }
  
  .intro-card:nth-of-type(2) h2{
    color:#1AB79D;
  }
  
  .intro-card:nth-of-type(2) {
    background-color:#b9e5dd;
  }


  @media (min-width: 400px) {
    .text-fontSize{
      font-size: 13px;
    }
    .intro-details{
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: row;
      text-align: left;
      gaP:35px;
      align-items: center;
      /* font-size: 80%; */
      justify-content: space-between;
    width: 100%;
    }
  }
  @media (min-width: 560px) {
    .text-fontSize{
      font-size: 15.5px;
    }
   
  }