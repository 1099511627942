.adv-container{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.advantages{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 26px;
}
.card {
    text-align: center;
    width: 330px;
    height: 160px;
    border: 1px solid #ccc;
    /* margin: 10px; */
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Center the content vertically */
    align-items: center;
    border-radius: 10px;
    color:black;
}
.card:hover{
    border: 1px solid aquamarine;
}
.card img{
    /* height: 100%; */
}
.card p{
    font-size: 90%;
}