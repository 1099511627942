.hero {
  margin: 30px 0;
  color: #fff;
  padding:0;
}
.hero a {
  color: #fff;
}
.hero .box {
  position: relative;
  padding: 30px;
}
.categoryN{
  position: absolute;
  right: 0;
  bottom: 0;
}
.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.hero .containerr {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 6.6vh);
  grid-gap: 10px;
}
.hero .box:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 9;
}
.hero .box:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}
.hero .box:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 9;
}

.hero .text h1 {
  font-weight: 500;
  margin: 10px 0;
  font-size: 15px;
}
.hero .author span {
  font-size: 14px;
  margin-right: 20px;
}
.hero .text {
  position: absolute;
  bottom: 30px;
}
.hero .box:nth-child(1) h1 {
  font-size: 18px;
}
.hero .box:nth-child(1) .categoryN {
  background-color: crimson;
}
.hero .box:nth-child(2) .categoryN {
  background-color: #0b9931;
}
.hero .box:nth-child(3) .categoryN {
  background-color: #c78823;
}
@media screen and (max-width: 768px) {
  .hero .containerr {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(18, 6vh);
    grid-gap: 10px;
  }
  .hero .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .hero .box:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 10;
  }
  .hero .box:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 15;
  }
  .hero .box:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
  }
}
