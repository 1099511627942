.head-introduct-container{
    padding: 0.5rem 0 0.5rem 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:row;
    color :black;
    /* background-color: aqua; */
  }
  .quote-container{
    /* width: 60%; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quote{
    
    /* background-color: orange; */
    margin: 30px;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* background-color: blue; */
  }
  .quote p{
    margin: 0;
    font-size: 15px;
  }
  .introduct-img-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .introduct-img{
    height: 80%;
    width: 80%;
    border-radius:  0 15% 0 15%;
  }

  
@media (min-width: 768px) {
    .quote-h2{
        font-size: 40px;
    }
    .head-introduct-container{
        padding: 1rem 0 1rem 0;
    }
}

@media (min-width: 992px) {

}

@media (max-width:450px) {
  .introduct-img-container{display: none;}
  .quote{
    text-align: center;
  }
  
}