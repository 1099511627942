.countries {
  padding-bottom: 3rem;
}
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  /* padding: 10px 0; */
  background: white;

  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;

}

.logos-slide img {
  height: 80px !important;
  margin: 0;
}
.containerCountry {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}
.containerCountry{
  color: black;
  text-align: justify;
}
.countryh3{
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .logos-slide img {
    height: 180px !important;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .containerCountry {
    display: flex;
    margin-top: 8rem;
    margin-bottom: 2rem;
    justify-content: center;
  }
}
