.admission-container{
    /* background-color: blueviolet; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    margin-bottom: 3rem;
  }
  .admission-details{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .admission-elements{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
  }
  .admission-img {
    padding: 0 0 2% 0 ;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
  }
  .admission-img img{
    height: 150px;
  }