
.allnews-centering{
  width:100%;
  /* background-color: rgb(255, 193, 193); */
  padding: 20px 0 20px 0;
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.all-news-container{
  display: flex;
  width: 100%;
  /* height: 1200px; */
  align-items: flex-start;
  align-content: flex-start;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  /* background-color: rgb(198, 198, 247); */
}
.all-news{
  width: 353px;
  height: 100%;
  /* background-color: aqua; */
}
.all-news-img{
  height: 50%;
  width: 100%;
}
.all-news-title{
  margin: 0;
  font-size: 80%;
  font-weight: bold;
  text-align: justify;
}
.all-news-floor{
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.all-news-btn{
  margin:0;
  padding:0 4px 0 4px;
  background-color: rgb(202, 202, 252);
  font-size: 80%;
}
.all-news-date{
  margin: 0;
  font-size: 80%;
}

.all-news-title:hover{
  text-decoration: underline;

}


@media (min-width: 768px) {

}

@media (min-width: 992px) {

}