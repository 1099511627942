.Charm {
  /* display: inline; */
}
.charms {
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  text-align: center;
  justify-content: space-around;
  /* background-color: rgb(12, 12, 43); */
}
.charm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.charmItem {
  /* margin: 0 40% 0 40%; */
  color: black;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* justify-content: space-between; Adjust as needed */
  align-items: center;
  width: 200px;

  /* background-color: blue; */
}
