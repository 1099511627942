.program-container{
  width: 100%;
  display:flex;
  align-items: center;
  flex-direction: column;
  color: black;
}
.head-container{
  width: 100%;
  max-width: 450px;
  height: 100%;
  background-color: #f5ca9a;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 1rem;
  align-items: center;
  margin:  20px 0 20px 0;
}

.category{
  /* width: 20%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}
.cat-title{
  /* height: 40px; */
  /* width: 100%; */
  font-size: 90%;
  
}
.cat-detail-container{
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.cat-details{
  display: flex;
  /* height: 100%; */
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start
}
.detail-p{
  margin: 5px;
  font-size: 90%;
}

.year{
  position:relative;
  bottom:0;
  background-color: #f3b46b;
  border-radius: 0.5rem;
  padding: 0  1rem 0 1rem ;
}



@media (min-width: 768px) {
    /* Program over768 */
    .head-container{
      width: 100%;
      max-width: 100%;
      height: 100%;
      background-color: #f5ca9a;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 1rem;
      align-items: center;
      margin:  20px 0 20px 0;
    }
    .cat-details{
      height: 180px;
    }
}

@media (min-width: 992px) {
  .head-container{
    flex-direction: row;
  }
  .category{
    width: 22%;
  }
  .cat-details{
    margin-left: 2rem;
    /* height: ; */
  }
  .detail-p{
    /* flex:1 */
  }
}
